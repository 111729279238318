import React from "react";

function EventComponent() {
  return (
    <>
      <div className="pmt_con">
        <div className="pmt_right">
          <div className="pmt_title">
            <h1>
              คาสิโนออนไลน์ เว็บตรงไม่ผ่านเอเย่นต์ไม่มีขั้นต่ำ น่าเชื่อถือ
              ที่สุด<br></br> ปลอดภัย 100%
            </h1>
          </div>
          <div className="pmt_text">
            <p>
              ไม่ผ่านเอเย่นต์ฝากถอนไม่มีขั้นต่ํา ไม่มีขั้นต่ำ ดีอย่างไร
              การเลือกเว็บพนันออนไลน์ <br></br>
              เพื่อเล่นเกมเดิมพันที่เราชอบนั้นก็เป็นอีกปัจจัยที่ทุกท่านควรคำนึงถึงเป็นอันดับแรกๆ
              การเล่นเกมสล็อต หรือ ฝากถอน ไม่มีขั้นต่ำ<br></br>
              นั้นมีข้อดีมากมาย อย่างเช่น เว็บตรง ไม่ผ่านเอเย่นต์ไม่มีขั้นต่ำ
              ที่นี้เท่านั้น โบนัส168.com(ภาษาไทย)<br></br>
              เพราะการที่ไม่ต้องผ่านตัวกลางจึงทำให้สามารถกำหนดโปรโมชั่น
              หรือเงื่อนไขดีๆ ได้เช่น การฝากถอนไม่มีขั้นต่ำ แต่หากไม่ใช่ สล็อต
              <br></br>
              เว็บตรงไม่ผ่านเอเย่นต์ ไม่มีขั้นต่ำ 2023
              บางทีเจ้าหน้าที่ต้องยืมกับทางเว็บแม่เพื่อคอยให้บริการแก่ลูกค้า
              ซึ่งบางทีอาจไม่เพียงพอ หรือล่าช้ามาก<br></br> ติดต่อยาก
              เนื่องจากเจ้าหน้าที่ 1 คนอาจต้องคอยดูแล 2-3 เว็บไซต์ต่อกะก็ได้
              และท่านจะไม่ถูกโกงแน่นอน สล็อตPG บาคาร่า บอล <br></br>{" "}
              เว็บตรงไม่ผ่านเอเย่นต์ไม่มีขั้นต่ํา
              ทำให้คุณสามารถสร้างรายได้จากเกม โบนัส168 ได้จริง<br></br>{" "}
              ผู้ให้บริการเว็บพนันออนไลน์ รวมเกมคาสิโนใว้หลากหลายเกม
              มีทุกค่ายให้ได้เล่นกัน ทั้ง สล็อตออนไลน์ คาสิโน บาคาร่า แทงบอล
              เว็บตรง<br></br> 
              คาสิโนออนไลน์ ที่ดีที่สุด เป็นเว็บหลัก เว็บตรง
              สมัครง่าย ทดลองเล่นได้เลย
            </p>
          </div>
        </div>
        <div className="pmt_left">
          <div className="pmt_img">
            <img src="./image/event/banner-mobile-01.svg" />
          </div>
        </div>
      </div>
    </>
  );
}

export default EventComponent;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImageData, sliderSettings } from "./image_data";
import("./Carousel.css");

function Carousel() {
  return (
    <div className="wrapper">
      <Slider {...sliderSettings} className="carousel">
        {ImageData.map((ImageData)=>(
          <div className="card">
            <div className="card_item">
              <img src={ImageData.img}/>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Carousel;

import React from 'react'
import Promotion_nav from './component/promotion_component/Promotion_nav'
import Header_pmt from './component/promotion_component/Header_pmt'
import Carousel_pm from './component/promotion_component/Carousel_pm'
import Footer from './component/footer/Footer'

function Promotion() {
  return (
    <>
      <Promotion_nav/>
      <Header_pmt/>
      <Carousel_pm/>
      <Footer/>
    </>
  )
}

export default Promotion
import React from "react";
import("./Content_BT.css");

function Content_BT() {
  return (
    <div className="bt_contaier">
      <div className="bt_content">
        <div className="bt_left">
          <div className="left_image">
            <img src="./image/PC-Pic-3.png" />
          </div>
          <div className="left_content">
            <h3 className="left_title ttc">
              PG เว็บตรงไม่มีขั้นต่ำสมัครฟรีเครดิตรับได้บ่อยทุกวัน
            </h3>
            <p className="left_text">
              สิ้นสุดการรอคอยสล็อตแตกง่าย
              เกมสล็อตยอดนิยมที่ทุกคนเรียกร้องและตามหามาอย่างยาวนาน เดิมพันง่าย
              อัตราจ่ายสูงถึงหลักล้าน เรียกได้ว่า เราเว็บตรงมักจะแตกสล็อต
              เราได้เลือกเกม ที่จะทำ ให้นักพนันเล่นแล้วแตกบ่อย เว็บตรงแตกง่าย
              ที่สุด มาให้เลือกเล่นได้เต็มที่ ลุ้นรางวัล โบนัส และ
              แจ็คพอตไม่จำกัดตลอดวัน
              ซึ่งการปั่นแต่ละครั้งไม่ต้องหลายทางก็ได้เงินรางวัลแบบคาดไม่ถึง
              พิเศษสุดๆ กับเกมต้นทุนต่ำ แตกง่าย ใหม่ล่าสุด 2023
              ที่อัพเดทเกมใหม่ๆ แถมใช้ทุนน้อยก็สามารถทำกำไรได้ เว็บ
              โดยตรงเสียง่าย
              เข้าง่ายด้วยระบบของเว็บตรงไม่ผ่านเอเย่นต์ที่ได้มาตรฐานสากล
            </p>
          </div>
        </div>
        <div className="bt_right">
          <div className="right_content">
            <h3 className="right_title ttc">
              ข้อสรุป : เล่น pg เว็บตรงแตกง่าย
            </h3>
            <p className="right_text">
              เว็บสล็อตอันดับ 1
              ลิขสิทธิ์แท้ส่งตรงจากอเมริกาเปิดให้บริการในไทยมาแล้วกว่า 4
              ปีด้วยระบบหลังบ้านตัวใหม่มาแรงล่าสุด
              ที่จะทำให้เปอร์เซ็นฝั่งผู้เล่นมีมากกว่าระบบแบบเก่า
              ซึ่งมีน้อยเว็บที่จะใช้เพราะนอกจากที่จะเพิ่มอัตตราการชนะให้กับผู้เล่นแล้วยังมีค่าใช้จ่ายเพิ่มเติมในการเลือกใช้ระบบใหม่อีกราคาสงวนอยู่ที่1ล้านบาท
              และแน่นอนเว็บเราโบนัส168มีให้บรการแล้ววันนี้ ภาพสวยคมชัดระดับFULL
              HD ทำรายการฝาก-ถอนรวดเร็วเพียงแค่15วินาทีเท่านั้น!!
            </p>
          </div>
          <div className="right_img">
            <img src="./image/PC-Pic-4.png" />
          </div>
        </div>
      </div>
      <div className="btn_bt">
        <div className="btn_t">
          <div className="btn_item">
            <i class="fa-solid fa-chevron-right ttc"></i>
            <a className="ttc" href="">
              <span>PG เว็บตรงไม่มีขั้นต่ำสมัครฟรีเครดิต</span>
            </a>
          </div>
          <div className="btn_item">
            <i class="fa-solid fa-chevron-right ttc"></i>
            <a className="ttc" href="">
              <span>PG เว็บตรงไม่มีขั้นต่ำสมัครฟรีเครดิต</span>
            </a>
          </div>
          <div className="btn_item">
            <i class="fa-solid fa-chevron-right ttc"></i>
            <a className="ttc" href="">
              <span>PG เว็บตรงไม่มีขั้นต่ำสมัครฟรีเครดิต</span>
            </a>
          </div>
          <div className="btn_item">
            <i class="fa-solid fa-chevron-right ttc"></i>
            <a className="ttc" href="">
              <span>PG เว็บตรงไม่มีขั้นต่ำสมัครฟรีเครดิต</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content_BT;

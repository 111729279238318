import React from "react";
import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import "./Contact_com.css";

function Contact_com() {
  const [bar_class, setBar] = useState("burger-bar  unclicked");
  const [navlist_class, setNavList] = useState("menu hidden");
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  const updateMenu = () => {
    if (!isMenuClicked) {
      setBar("burger-bar clicked");
      setNavList("menu visible");
    } else {
      setBar("burger-bar unclicked");
      setNavList("menu hidden");
    }
    setIsMenuClicked(!isMenuClicked);
  };
  return (
    <>
      <header>
        <div className="navbar_2">
          <div className="nav2_top">
            <div className="burger-menu burger2" onClick={updateMenu}>
              <div className={bar_class}></div>
              <div className={bar_class}></div>
              <div className={bar_class}></div>
            </div>
            <div className="nav2_logo">
              <Link to="/">
                <img src="./image/Bonus-Logo.png" />
              </Link>
            </div>
            <div className="nav2_btn">
              <div className="nav2_login">
                <a href="">
                  <img src="./image/PC-Login.png" />
                </a>
              </div>
              <div className="nav2_register">
                <a href="">
                  <img src="./image/PC-Register.png" />
                </a>
              </div>
            </div>
          </div>
          <div className="nav">
            <ul className={navlist_class}>
              <div className="btnPhone">
                <div className="nav2_login">
                  <a href="">
                    <img src="./image/PC-Login.png" />
                  </a>
                </div>
                <div className="nav2_register">
                  <a href="">
                    <img src="./image/PC-Register.png" />
                  </a>
                </div>
              </div>
              <li className="list-item">
                <Link to="/">
                  <img src="./image/house.png" />
                  หน้าหลัก
                </Link>
              </li>
              <li className="list-item ">
                <Link to="/promotion">
                  <img src="./image/megaphone.png" />
                  โปรโมชั่น
                </Link>
              </li>
              <li className="list-item">
                <Link to="/event">
                  <img src="./image/dice.png" />
                  กิจกรรม
                </Link>
              </li>
              <li className="list-item active">
                <Link to="/contact">
                  <img src="./image/chat.png" />
                  ติดต่อเรา
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <Outlet />

      <div className="contact_con ">
        <div className="contact">
          <div className="contact_logo ">
            <a href="">
              <img src="./image/Bonus-Logo.png" />
            </a>
          </div>
          <div className="qr_contact ">
            <img src="./image/qrcode.png" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact_com;

import React from 'react'
import ('./Header_pmt.css')

function Header_pmt() {
  return (
    <>
        <div className="pmt_con">
            <div className="pmt_right">
                <div className="pmt_title">
                    <h1>คาสิโนเว็บตรง มีครบทั้งบาคาร่า สล็อต<br></br> คาสิโนไม่มีขั้นต่ำสมัครฟรีเครดิตรับได้บ่อยทุกวัน</h1>
                </div>
                <div className="pmt_text">
                    <p>สิ้นสุดการรอคอยสล็อตแตกง่าย เกมสล็อตยอดนิยมที่ทุกคนเรียกร้องและตามหามาอย่างยาวนาน เดิมพันง่าย <br></br> 
                    อัตราจ่ายสูงถึงหลักล้าน เรียกได้ว่า เราเว็บตรงมักจะแตกสล็อต เราได้เลือกเกม ที่จะทำให้นักพนันเล่นแล้วแตกบ่อย <br></br>
                    เว็บตรงแตกง่าย ที่สุด มาให้เลือกเล่นได้เต็มที่ ลุ้นรางวัล โบนัส และแจ็คพอตไม่จำกัดตลอดวัน<br></br>
                    ซึ่งการปั่นแต่ละครั้งไม่ต้องหลายทางก็ได้เงินรางวัลแบบคาดไม่ถึง พิเศษสุดๆ กับเกมต้นทุนต่ำ แตกง่าย ใหม่ล่าสุด 2023 <br></br>
                    ที่อัพเดทเกมใหม่ๆ แถมใช้ทุนน้อยก็สามารถทำกำไรได้ เว็บโดยตรงเสียง่าย <br></br> เข้าง่ายด้วยระบบของเว็บตรงไม่ผ่านเอเย่นต์ที่ได้มาตรฐานสากล</p>
                </div>
            </div>
            <div className="pmt_left">
                <div className="pmt_img">
                    <img src="./image/promotion/banner-mobile-01.png" />
                </div>
            </div>
        </div>
    </>
  )
}

export default Header_pmt
import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";

import("./Navbar.css");

function Navbar() {
  const [bar_class, setBar] = useState("burger-bar  unclicked");
  const [navlist_class, setNavList] = useState("menu hidden");
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  const updateMenu = () => {
    if (!isMenuClicked) {
      setBar("burger-bar clicked");
      setNavList("menu visible");
    } else {
      setBar("burger-bar unclicked");
      setNavList("menu hidden");
    }
    setIsMenuClicked(!isMenuClicked);
  };

  const [LogoSize, setLogoSize] = useState("9rem");
  const listenScrollEvent = () => {
    window.scrollY > 1 ? setLogoSize("4rem") : setLogoSize("9rem");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
    <>
      <header>
        <div className="logo-container">
          <div className="logo">
            <a href="/">
              <img 
                style={{
                height: LogoSize,
                transition: "all 1s",
              }} src="./image/Bonus-Logo.png" />
            </a>
            <div className="burger-menu" onClick={updateMenu}>
              <div className={bar_class}></div>
              <div className={bar_class}></div>
              <div className={bar_class}></div>
            </div>
          </div>
          <div className="nav">
            <ul className={navlist_class}>
              <li className="list-item active">
                <Link to="/">
                  <img src="./image/house.png" />
                  หน้าหลัก
                </Link>
              </li>
              <li className="list-item">
                <Link to="/promotion">
                  <img src="./image/megaphone.png" />
                  โปรโมชั่น
                </Link>
              </li>
              <li className="list-item">
                <Link to="/event">
                  <img src="./image/dice.png" />
                  กิจกรรม
                </Link>
              </li>
              <li className="list-item">
                <Link to="/contact">
                  <img src="./image/chat.png" />
                  ติดต่อเรา
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <Outlet />
    </>
  );
}

export default Navbar;

import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArticleImage, sliderSettings } from '../carousel/image_data'
import ('./Article.css')

function Article() {
  return (
    <div className="article">
        <Slider {...sliderSettings} className="article-carousel">
        {ArticleImage.map((ArticleImage)=>(
          <div className="card">
            <div className="card_item">
              <img src={ArticleImage.img}/>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Article
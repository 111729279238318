import React from 'react';
import Navbar from './component/navbar/Navbar';
import Hero from './component/hero/Hero';
import ContentTop from './component/contentTop/ContentTop';
import Carousel from './component/carousel/Carousel';
import Content_CT from './component/content_center/Content_CT';
import Article from './component/article/Article';
import Content_BT from './component/content_bottom/Content_BT';
import Footer from './component/footer/Footer';

function Home() {
  return (
    <div className="Home">
      <Navbar/>
      <Hero/>
      <ContentTop/>
      <Carousel/>
      <Content_CT/>
      <Article/>
      <Content_BT/>
      <Footer/>
    </div>
  );
}

export default Home;

export const promotionImage = [
    {
        img: "./image/promotion/promotion1.svg",
    },
    {
        img: "./image/promotion/promotion2.svg",
    },
    {
        img: "./image/promotion/promotion3.svg",
    },
    {
        img: "./image/promotion/promotion4.svg",
    },
    {
        img: "./image/promotion/promotion5.svg",
    },
    {
        img: "./image/promotion/promotion1.svg",
    },
    {
        img: "./image/promotion/promotion2.svg",
    },
    {
        img: "./image/promotion/promotion3.svg",
    },
    {
        img: "./image/promotion/promotion4.svg",
    },
    {
        img: "./image/promotion/promotion5.svg",
    },
];

export const EventImage = [
    {
        img:"./image/event/event1.svg"
    },
    {
        img:"./image/event/event2.svg"
    },
    {
        img:"./image/event/event3.svg"
    },
    {
        img:"./image/event/event1.svg"
    },
    {
        img:"./image/event/event2.svg"
    },
    {
        img:"./image/event/event3.svg"
    },
]

export const sliderProSettings = {
    dots: true,
    infinite: true,
    autoplay:true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    touchMove: true,
    useCSS: true,

    responsive: [
    {
        breakpoint: 1024,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
        },
        },
        {
        breakpoint: 1000,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
        },
        },
        {
        breakpoint: 480,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },
    },
    ],
};

export const sliderEventSettings = {
    dots: true,
    infinite: true,
    autoplay:true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    touchMove: true,
    useCSS: true,

    responsive: [
    {
        breakpoint: 1024,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
        },
        },
        {
        breakpoint: 1000,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
        },
        },
        {
        breakpoint: 480,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },
    },
    ],
};

import React from 'react'
import './hero.css'

function Hero() {
  return (
    <div className='hero-container'>
        <div className="hero">
            <div className="hero_button">
                <a className="hero_register" href=""><img src="./image/PC-Register.png" alt=""/></a>
                <a className="hero_login" href=""><img src="./image/PC-Login.png" alt=""/></a>
            </div>
        </div>
    </div>
  )
}

export default Hero
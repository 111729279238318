import React from 'react'
import Contact_com from './component/contact/Contact_com'
import Footer from './component/footer/Footer'

function Contact() {
  return (
    <>
        <Contact_com/>
        <Footer/>
    </>
  )
}

export default Contact
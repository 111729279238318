import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { EventImage, sliderEventSettings } from "../../../util/data";

function Event_Carousel() {
  return (
    <>
      <div className="carousel_pm">
        <div className="crs_container">
          <Slider {...sliderEventSettings} className="carousel">
            {EventImage.map((EventImage) => (
              <div className="card">
                <div className="card_item">
                  <img src={EventImage.img} />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="line-color">
      </div>
    </>
  );
}

export default Event_Carousel;

import React from 'react'
import ('./ContentTop.css')

function ContentTop() {
  return (
    <div className="content_top-ct col-md-12">
        <marquee className="py-2">ประกาศ : บาคาร่าออนไลน์ เราพร้อมให้บริการทุกท่านตลอด 24 ชั่วโมง มีระบบฝาก-ถอนอัตโนมัติ รวดเร็ว ปลอดภัย 100%</marquee>
        <div className="circle-container col-md-12">
            <div className="circle">
                <div className="baccarar"><img src="./image/PC-Baccarat-Button.png"/></div>
                <div className="slot"><img src="./image/PC-Slot-Button.png"/></div>
                <div className="football"><img src="./image/PC-Football-Button.png"/></div>
                <div className="lottery"><img src="./image/PC-Lottery-Button.png"/></div>
                <div className="fishing"><img src="./image/PC-Fishing-Button.png"/></div>
            </div>
        </div>
        <div className="content_t text-center mt-5">
            <div className="">
            <h1 className="content_title col-md-12">PG เว็บตรงแตกง่าย สมัครง่าย ฝาก-ถอน 24 ชั่วโมง</h1>
            <p className="content_text col-md-12">เกมสล็อตบนเว็บโดยตรงคือเกม สล็อตเว็บตรง ที่เราสามารถเล่นได้บนเว็บไซต์ เว็บพนันออนไลน์ ที่คุณสามารถลงทุนได้โดยที่เงินของคุณไม่ต้องผ่านตัวกลางหรือเอเย่นต์โดยสามารถสังเกต
<br></br>ได้ง่ายๆจากระบบการฝาก-ถอน เว็บไหนมี ฝาก-ถอน อัตโนมัติ คุณจึงมั่นใจได้ว่า เป็นเว็บไซต์ที่ปลอดภัยแน่นอน เพราะคุณทำธุรกรรมการเงินกับเว็บโดยตรง เงินเลยไม่ต้องผ่านมือคนอื่น<br></br>
และยังสะดวกสบายยิ่งขึ้น โอนเงินด้วยตัวเองได้ง่ายๆ ด้วยทรูวอลเล็ท และแอพธนาคารได้แล้ววันนี้ เล่นเกมได้นานโดยไม่สะดุดเพียง 10 วินาทีของการกระทำ เกมสล็อตเว็บตรง เป็นเกมที่ให้ผลตอบแทนสูง<br></br>
เป็นที่นิยมอย่างมาก ลงทุนต่ำ สล็อต และเล่นได้ทุกที่ ได้ทุกเวลาที่คุณต้องการ ในอดีตหากต้องการเล่นเกมพนันออนไลน์ คุณจะต้องเดินทางไปยังคาสิโนต่างๆ ซึ่งถือว่า มีความเสี่ยงค่อนข้างสูง<br></br>
แต่ปัจจุบันเกมนี้เข้าถึงได้ง่าย เล่นบนเว็บไซต์ได้เลยไม่ต้องโหลดแอพ หรือทำอะไรให้ยุ่งยากเลย และเรายังได้รวบรวมเกมดีๆ แจ็คพอต โบนัส เครดิต ฟรีสปิน มาให้เดิมพันแบบไม่จำกัดอีกด้วย<br></br>
เราคือศูนย์รวมเกมส์สล็อตที่มีเกมส์สนุกๆให้ท่านเลือกมากถึง 700 รายการ เลยทีเดียว ข้อดีอีกประการของทางเข้าเว็บสล็อตโดยตรงคือช่วยเพิ่มความปลอดภัยของผู้เล่น สำหรับคาสิโนแบบดั้งเดิม<br></br>
ผู้เล่นจะต้องให้ข้อมูลส่วนตัวและข้อมูลทางการเงินซึ่งอาจเสี่ยงต่อแฮ็กเกอร์และผู้ประสงค์ร้ายอื่นๆ อย่างไรก็ตามทางเข้าเว็บสล็อตโดยตรงทำให้มั่นใจได้ว่าข้อมูลทั้งหมดจะถูกจัดเก็บอย่างปลอดภัย<br></br>
ในเซิร์ฟเวอร์ที่ปลอดภัย ปกป้องข้อมูลของผู้เล่นจากการถูกเข้าถึงโดยบุคคลที่ไม่ได้รับอนุญาต</p>
            </div>
        </div>
    </div>
  )
}

export default ContentTop
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { promotionImage, sliderProSettings } from "../../util/data";
import("./Carousel_pm.css");

function Carousel_pm() {
  return (
    <>
      <div className="carousel_pm">
        <div className="crs_container">
          <Slider {...sliderProSettings} className="carousel">
            {promotionImage.map((promotionImage) => (
              <div className="card">
                <div className="card_item">
                  <img src={promotionImage.img} />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="line-color">
      </div>
    </>
  );
}

export default Carousel_pm;

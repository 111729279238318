import React from 'react'
import ('./Content_CT.css')

function Content_CT() {
  return (
    <div className="center_container">
        <div className="content_ct">
            <div className="content_left">
                <h2 className="ct_title ttc text-center">เว็บอันดับ 1 สำหรับสายเสี่ยงดวง</h2>
                <div className="button_list">
                    <div className="button_item"><a href=""><img src="./image/PC-Register.png"/></a></div>
                    <div className="button_item"><a href=""><img src="./image/PC-Login.png"/></a></div>
                    <div className="button_item"><a href=""><img src="./image/PC-Contact-2.png"/></a></div>
                </div>
                <div className="qrCode mt-4">
                    <img src="./image/qrcode.png"/>
                    <img src="./image/PC-Ios.png"/>
                </div>
            </div>
            <div className="content_right mb-4">
                    <img src="./image/PC-Pic-2.png"/>
            </div>
        </div>
        <div className="text_content_CT text-center">
            <p>พร้อมให้ท่านได้เข้ามาสัมผัสกับประสบการณ์ที่ดีที่สุด สล็อตออนไลน์ในการเล่นเกมสล็อตแตกง่าย ได้เงินจริง ที่เว็บอื่น ๆ ไม่สามารถมอบให้กับท่านได้ สำหรับท่านใดที่กำลังมองหา<br></br>
                เว็บเกมสล็อตรวมทุกค่ายทั่วโลก สามารถเข้าเล่นได้ที่ salot888.com ง่ายไม่ผ่านเอเย่นต์ เว็บตรง อันดับ 1 ของโลก ที่มีอัตราการแจกเงินรางวัลที่สูงกว่าที่อื่น ๆ รางวัลโบนัสแจ็กพอตแตกง่าย<br></br>
                ได้เงินจริง ลุ้นรับเงินรางวัลได้ทุกวัน การันตีจากประสบการณ์ผู้ใช้งานจริง งบน้อย ก็สามารถทำกำไรได้แล้ว เว็บตรง อันดับ 1 ของโลก เข้ามาเล่นเว็บเดียว ตอบโจทย์ทุกความต้องการอย่างแน่นอน<br></br>
                เล่นได้เท่าไหร่ ถอนได้ครบทุกบาททุกสตางค์ ไม่มีการหักเปอร์เซ็นต์ใด ๆ ทั้งสิ้น รับเงินได้เต็มจำนวน
            </p>
        </div>
        <h2 className="text-center ttc article_text">รวมบทความจากเรา</h2>
    </div>
  )
}

export default Content_CT
import React from "react";
import("./Footer.css");

function Footer() {
  return (
    <div className="footer_con">
      <div className="footer container-fulid">
        <div className="row ft_content">
          <div className="footer_col col-md-4">
            <div className="left_footer">
              <div className="ft_left">
                <img className="logo_ft" src="./image/Bonus-Logo.png" />
              </div>
              <div className="social socail_pc">
                <img src="./image/social.png" />
              </div>
            </div>
          </div>
          <div className="footer_col center col-md-4 ">
            <div className="ft_center">
              <h2 className="ttc ft_title">รองรับทุกธนาคารชั้นนำ</h2>
              <div className="bank">
                <img src="./image/PC-Footer-Bank.png" />
              </div>
              <p>บริการ ฝาก - ถอน ออโต้รวดเร็ว มั่นคง ปลอดภัย ไม่ต้องรอ</p>
            </div>
          </div>
          <div className="footer_col right col-md-4 ">
            <div className="ft_right">
              <div className="item_top">
                <div className="ft_list">
                  <span className="ttc">บาคาร่า</span>
                </div>
                <div className="ft_list">
                  <span className="ttc">pg2023</span>
                </div>
                <div className="ft_list">
                  <span className="ttc">สล็อตเว็บตรง</span>
                </div>
              </div>
              <div className="item_center">
                <div className="ft_list">
                  <span className="ttc">สล็อต</span>
                </div>
                <div className="ft_list">
                  <span className="ttc">บาคาร่าเว็บตรง</span>
                </div>
                <div className="ft_list">
                  <span className="ttc">เซ็กซี่บาคาร่า</span>
                </div>
              </div>
              <div className="item_bottom">
                <div className="ft_list">
                  <span className="ttc">โบนัส168</span>
                </div>
                <div className="ft_list">
                  <span className="ttc">เว็บตรง2023</span>
                </div>
                <div className="ft_list">
                  <span className="ttc">เครดิตฟรี</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="social socail_phone">
        <img src="./image/social.png" />
      </div>
    </div>
  );
}

export default Footer;

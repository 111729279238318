import React from "react";
import EventComponent from "./component/event _component/nav/EventComponent";
import EventNav from "./component/event _component/nav/EventNav";
import Event_Carousel from "./component/event _component/nav/EventCarousel";
import Footer from "./component/footer/Footer";

function Event() {
  return (
    <>
      <EventNav/>
      <EventComponent/>
      <Event_Carousel/>
      <Footer/>
    </>
  );
}

export default Event;

export const ImageData = [
    {
        img: "./image/bonus1.png",
    },
    {
        img: "./image/bonus2.png",
    },
    {
        img: "./image/bonus3.png",
    },
    {
        img: "./image/bonus1.png",
    },
    {
        img: "./image/bonus2.png",
    },
    {
        img: "./image/bonus3.png",
    },
];

export const ArticleImage = [
    {
        img: "./image/PC-Article-1.png",
    },
    {
        img: "./image/PC-Article-2.png",
    },
    {
        img: "./image/PC-Article-3.png",
    },
    {
        img: "./image/PC-Article-1.png",
    },
    {
        img: "./image/PC-Article-2.png",
    },
    {
        img: "./image/PC-Article-3.png",
    },
];

export const sliderSettings = {
    dots: true,
    infinite: true,
    autoplay:true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    touchMove: true,
    useCSS: true,

    responsive: [
    {
        breakpoint: 1024,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
        },
        },
        {
        breakpoint: 1000,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
        },
        },
        {
        breakpoint: 480,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },
    },
    ],
};
